@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_scroll.scss';

@layer base {
  :root {
    --background: 31, 43%, 85%;
    --foreground: 6, 53%, 14%;
    --background-hover: 20, 6%, 90%;

    --primary: 338, 91%, 32%;
    --primary-foreground: 0, 0%, 100%;

    --wave: 6, 53%, 14%;
    --wave-foreground: 26, 59%, 88%;

    --border: 338, 91%, 32%;
    --muted: 0 0% 100%;
  }

  .dark {
    --background: 0, 17%, 11%;
    --foreground: 20, 6%, 90%;
    --background-hover: 0, 17%, 13%;

    --primary: 21, 45%, 87%;
    --primary-foreground: 0, 17%, 7%;

    --wave: 0, 17%, 7%;
    --wave-foreground: 20, 6%, 90%;

    --border: 339, 34%, 33%;
    --muted: 0 0% 100%;
  }
}

@layer base {
  * {
    @apply box-border;
  }
  body {
    @apply bg-background transition-colors;
  }
}

::selection {
  background: hsl(var(--primary));
  color: hsl(var(--primary-foreground));
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
